import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAxios } from '../../../../hooks/useAxios';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Generated  from '../../../../components/system/Generated';
import ToggleStatusBtn from '../../../../components/buttons/ToggleStatus';
// import EmailError from '../system/EmailError';

import UploadPlan from './UploadPlan';
import OcrBtn from '../../../../components/buttons/OcrBtn';

import dayjs from 'dayjs'
import { emptyObj } from '../../../../utils/functions';
import { formObj } from '../../../../utils/schemas';
import { findPhasePlan } from '../../../../utils/phases';

const schema = [
  { id: '1', type: 'col-12', label: '1', col: [
    { id: '2', type: 'hidden', field: 'id', },
    { id: '3', type: 'floating-input', inputType: 'text', field: 'name', label: 'Plan Name', required: 'Please enter a plan name.' },
    { id: '4', type: 'floating-input', inputType: 'text', field: 'code', label: 'Plan Code (e.g. 01 or 02)' },
    { id: '5', type: 'hidden', field: 'status', },
    { id: '6', type: 'html', content: '<p class="text-muted">Plan codes are used in map labels,<br />e.g. <span class="text-danger fw-bold text-decoration-underline">01</span>-ST-004    </p>'}
  ]},
]

const View = (props) => {
  const debug = true;
  const { userDetails, obj, project, phases, activePhase, setPlans, phasePlans, setPhasePlans, handleClose } = props;
  const formText = { submit: `Update`, pending: 'Updating', success: 'Updated!', cancel: 'Cancel' };
  const errorCode = '9981'

  const { serverCall, pending, error } = useAxios();
  const { control, register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm({
    defaultValues: useMemo(() => formObj(schema), [])
  });

  const toggleStatus = watch('status');
  const [phasePlan, setPhasePlan] = useState({});

  useEffect(() => {
    if(!obj) return;

    let filled = formObj(schema, obj);
    reset(filled);

    let filtered = phasePlans.filter(x => x.planId === obj.id);
    let found = findPhasePlan(phases, activePhase, filtered);
    found ? setPhasePlan(found) : setPhasePlan({});
  }, [obj, reset, activePhase, phasePlans, phases])

  const onSubmit = async (data) => {
    if(debug) console.log(data);

    setPlans((prev) => {
      let arr = [...prev];
      let idx = arr.findIndex(x => x.id === obj.id);
      arr[idx] = {...arr[idx], ...data}
      return arr;
    })

    data.updatedAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    data.updatedBy = userDetails.id;

    let res = await serverCall({ method: 'PATCH', data, url: `/mapping/plans/${obj.appId}`, eamsAuth0: true });
    if(res.status!==200) return alert('Error updating plan. Contact support.') // lklklk
  };

  const fromChild = async (data) => {
    const { type, value } = data;
    if(type === 'toggle status') {
      setValue('status', value);
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className="d-flex flex-column h-100 overflow-hidden">
      <Offcanvas.Header className="bg-body" closeButton>
        <Offcanvas.Title>Edit Plan</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="bg-body flex-grow-1 overflow-scroll">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="mb-3">
          <Generated id="type-edit" schema={schema} size="md" pieces={{ register, control, setValue, watch, errors, toParent: fromChild }} />
        </form>
        <hr />
        <h5>Swap Background { phases.filter(x => x.status==='A').length > 1 ? `: ${activePhase.name}` : ''}</h5>
        { !emptyObj(phasePlan) && (
          <figure>
            <a href={phasePlan?.url} target="_blank" rel="noreferrer">
              <img src={phasePlan?.url} className="img-fluid border" alt="Plan Background" />
            </a>
            <figcaption className="small text-muted mt-1">Uploaded {phasePlan?.updatedAt}</figcaption>
          </figure>
        )}

        {/* lklklk should the upload plan also ... include the phaseId? */}
        <UploadPlan key={obj.appId} project={project} activePlan={obj} activePhase={activePhase} setPlans={setPlans} setPhasePlans={setPhasePlans} />

        { !emptyObj(phasePlan) && (
          <Fragment>
            <hr />
            <OcrBtn type="phasePlan" obj={phasePlan} setPhasePlans={setPhasePlans} userDetails={userDetails} text="Read Plan (OCR)" classes="btn btn-sm btn-outline-primary" />
          </Fragment>
        )}

      </Offcanvas.Body>
      <div className="bg-body p-3">
        <button className={`btn btn-sm ${error ? 'btn-danger' : 'btn-success'} float-end`} type="submit" disabled={pending} onClick={() => handleSubmit(onSubmit)() }>
          { !pending && !error && (formText.submit)}
          { pending === true && (
            <Fragment>
              <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              {formText.pending}
            </Fragment>
          )}
          { error && (`Error saving (${errorCode})`)}
          { pending ==='success' && formText.success}
        </button>

        <button className="btn btn-sm btn-outline-dark ms-auto me-1 float-end" type="button" onClick={handleClose}>
          {formText.cancel}
        </button>

        <ToggleStatusBtn toParent={fromChild} status={toggleStatus} textA="Delete Plan" textD="Reactivate Plan" />
      </div>
    </div>
  )
}

export default View;
