import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAxios }   from '../hooks/useAxios';

import Loading  from '../components/system/Loading';
import Error    from '../components/system/Error';
import RegisterEmail from '../views/system/RegisterEmail';

import { SiteContext } from './SiteContext';
import { userArray, accessArray } from '../utils/functions';
import db from '../utils/dexie-provider.js'

import dayjs from 'dayjs';
import NoticeModal from '../components/_products/sfo/NoticeModal';

// lklklk the 12 hour thing causes the old email to be stored
// even if they switched accounts; need to clear out the lastSync
// when they logout to avoid this

// also need to add a manual refresh so we get updated schemas...?

// userGroups is an array with all user group info
// userGroupOpts is the mapped value/label for dropdowns or selects

// the profile "sub" key includes either email|, sms| or oidc|
// perhaps for all users we should just store the name and email address...

export const UserContext  = createContext({});

export const UserProvider = ({ type, children }) => {
  const { user, isLoading, isAuthenticated } = useAuth0();
  const location = useLocation();
  const { hostname, config, online } = useContext(SiteContext);
  const { serverCall, error } = useAxios();

  const [loading, setLoading] = useState(false);
  const [userError, setUserError] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [highestRole, setHighestRole] = useState('viewer');
  const [allGroups, setAllGroups] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [checklists, setChecklists] = useState([]);
  const [typeChecklists, setTypeChecklists] = useState([]);
  const [libraries, setLibraries] = useState([]);

  const noticeDays = 14;
  const [notices, setNotices] = useState([]);
  const [newNotices, setNewNotices] = useState(0);

  const [register, setRegister] = useState(false);

  useEffect(() => {
    if(!user || isLoading || !isAuthenticated) return;
    if(location?.pathname === '/logout') return;

    let uuid  = user['https://mstdsqr.com/app_metadata']?.uuid;
    let email = user['https://mstdsqr.com/user_metadata']?.email;

    // if no uuid or email, force registration
    if(!uuid || !email) return setRegister(true);

    // lklklk: technically if the user obj contains email + verified they are authed
    // should you set up a different user call/post where you get them by their email?
    // would that cause any security issues?
    // if(email) {
    //   console.log("yoooo")
    //   return;
    // }

    if(hostname.includes('survey') || hostname.includes('inspect')) {
      (async () => {
        try {
          setLoading(`Getting your ${config?.terms?.task?.[1] ?? 'surveys'}...`);
          let lastSync = await db.system.get('lastUserSync');
          let resync = 60 * 60 * 1000 * 12; // 12 hours
          let needSync = (Date.now() - lastSync?.value) > resync ? true : false;
  
          // check if we haven't synced and/or lastSync > 2 hours
          if((!lastSync || needSync) && online) {
            let res = await serverCall({
              method: "GET", url: `/system/users/${uuid}`, eamsAuth0: true,
              headers: { 'eams-key': email, 'eams-access': uuid }
            });

            // if no user is found, check if this site allows self-register
            if(res.status===204 && config.selfRegister)
              return setRegister(true);
            else if(res.status!==200)
              return setUserError(true);

            // console.log(res.data);
            let details = res.data.user;
            details.byUser = {
              id: details.id,
              email: details.email,
              firstName: details.firstName,
              lastName: details.lastName
            }
  
            await db.schemas.bulkPut(res.data.schemas);
            await db.libraries.bulkPut(res.data.libraries);
            await db.checklists.bulkPut(res.data.checklists);
            await db.typeChecklists.bulkPut(res.data.typeChecklists);
            await db.system.put({ name: 'userDetails', value: details});
            await db.system.put({ name: 'lastUserSync', value: Date.now()});
          }

          // offline or stored
          let schemas = await db.schemas.toArray();
          let libraries = await db.libraries.toArray();
          let checklists = await db.checklists.toArray();
          let typeChecklists = await db.typeChecklists.toArray();
          let details = await db.system.get('userDetails');
          setUserDetails(details.value);
          setSchemas(schemas);
          setLibraries(libraries);
          setChecklists(checklists);
          setTypeChecklists(typeChecklists);
          setLoading(false);
  
        } catch (error) {
          console.log(error);
          alert('Error.'); // lklklk
        }
      })();

      return;
    }

    (async () => {
      try {
        setLoading(`Getting your ${config?.terms?.project?.[1] ?? 'projects'}...`);
        let res = await serverCall({
          method: "GET", url: `/system/users/${uuid}`, eamsAuth0: true,
          headers: { 'eams-key': email, 'eams-access': uuid }
        });

        // if no user is found, check if this site allows self-register
        if(res.status===204 && config.selfRegister)
          return setRegister(true);
        else if(res.status!==200)
          return setUserError(true);

        // console.log(res.data);
        let details = res.data.user;
        details.byUser = {
          id: details.id,
          email: details.email,
          firstName: details.firstName,
          lastName: details.lastName
        }

        // check lastSeen to see if we need to alert for notifications
        // check the number of days beween now and the latest notice
        if(res.data.notices) {
          let lastSeen = details.lastSeen ? dayjs(details.lastSeen) : dayjs().subtract(noticeDays, 'day');
          let newOnes = res.data.notices?.filter(x => x.published==='Y' && x.status==='A' && dayjs(x.effectiveDate).isAfter(lastSeen));
          if(newOnes?.length > 0) setNewNotices(newOnes.length);
        }

        console.log(res.data);
        setUserDetails(details);
        setAllGroups(res.data.groups);
        setSchemas(res.data.schemas);
        setNotices(res.data.notices);
        setLibraries(res.data.libraries);
        setChecklists(res.data.checklists);

        // determine user's highest role for add/admin buttons
        let groupStr = JSON.stringify(details.groupArr);
        let highest = 'viewer';
        if(details.supe) highest = 'admin';
        else if(groupStr.includes('owner')) highest = 'admin';
        else if(groupStr.includes('admin')) highest = 'admin';
        else if(groupStr.includes('editor')) highest = 'editor';
        else if(groupStr.includes('contributor')) highest = 'contributor';
        setHighestRole(highest);

        // format project managedByDisplay
        if(res.data.projects) {
          let proj = [...res.data.projects];
          for (const project of proj) {
            if(project.managedBy.length === 0) continue;
            project.managedByDisplay = userArray(res.data.users, project.managedBy);
          }
          setProjects(proj);
        }

        if(res.data.tasks) {
          // format task assigned to
          let tasks = [...res.data.tasks];
          for (const task of tasks) {
            let projectIds = task.parentIds.filter(x => x.type==='project').map(x => x.id);
            let linked = [];
            for (const id of projectIds) {
              let found = res.data.projects.find(x => x.id === id);
              if(found) {
                let str = `<a href="/project/${found.appId}">${found.name} (${String(found.id).padStart(3, '0')})</a>`;
                linked.push(str);
              }
            }
            linked = linked.join('<br />');
            task.linkedProject = linked;

            if(task.assignedTo.length === 0) continue;
            task.assignedToDisplay = userArray(res.data.users, task.assignedTo);
          }
          setTasks(tasks);
        }

        if(res.data.users) {
          let users = [...res.data.users];
          for (const user of users) {
            user.rolesAccess = accessArray(res.data.groups, user.groupArr);
          }
          setAllUsers(users);
        }

        // lastly, setRegister to false in case they were registering
        setRegister(false);

        // // finally, check for any localStorage for a share redirect
        // let redirect = localStorage.getItem('share_redirect');
        // if(!redirect) return;

        // redirect = JSON.parse(redirect);
        // if(redirect.path) {
        //   navigate(redirect.path);
        //   localStorage.removeItem('share_redirect');
        // }

        setLoading(false);
      } catch (error) {
        console.log(user);
        console.log(error);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading, isAuthenticated])

  if(loading) return ( <Loading msg={loading} />);
  // lklklk you need to handle this... if you just do a return
  // the sockets etc. all disconnect...
  if(userError || error) return ( <Error msg="Not authorized. (001)" />)

  // if this person doesn't have any user groups or assigned projects,
  // we probably want to forward them to a URL that is based on the site config

  // or... are we just going to show 'my tickets' ... ?

  // right now WFI + WGS only register via email,
  // ICP will need to register via phone

  return (
    <UserContext.Provider value={{
        userDetails,
        highestRole,
        allGroups,
        setAllGroups,
        allUsers,
        setAllUsers,
        schemas,
        setSchemas,
        projects,
        setProjects,
        tasks,
        setTasks,
        libraries,
        setLibraries,
        checklists,
        setChecklists,
        typeChecklists,
        setTypeChecklists,
        notices,
        setNotices,
        newNotices,
        setNewNotices
      }}>

        { register && <RegisterEmail /> }
        {!register && children }

        <NoticeModal newNotices={newNotices} />
    </UserContext.Provider>
  );
};
