import React, { Fragment, useContext, useState } from 'react';
import { useAxios } from '../../hooks/useAxios';
import { getOcr } from '../../context/workers/tesseract/init';
import { emptyObj } from '../../utils/functions';
import dayjs from 'dayjs'

// future... will want to use something similar for the manual map/photo reads

const View = (props) => {
  const { type, obj, setPhasePlans, userDetails } = props;
  const { serverCall } = useAxios();
  const wrapper = props.wrapper ? props.wrapper : 'me-2';
  const classes = props.classes ? props.classes : 'btn btn-outline-primary';
  const text = props.text ? props.text : 'OCR';

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const download = async () => {
    console.clear();
    if(emptyObj(obj)) return;
    setLoading(true);

    console.log(obj);
    // let newUrl = await serverCall({ method: 'POST', data: {}, url: `/system/`, eamsAuth0: true });
    // if(res.status!==200) return alert('Error updating ocr data. Contact support.') // lklklk

    // let output = await getOcr('https://tesseract.projectnaptha.com/img/eng_bw.png', setProgress);

    if(type==='phasePlan') {
      console.log("yep");
      let output = await getOcr(obj.url, setProgress, false);
      setProgress('Saving...');

      setPhasePlans((prev) => {
        let arr = [...prev];
        let idx = arr.findIndex(x => x.id === obj.id && x.appId === obj.appId);
        if(idx > -1) arr[idx].ocr = output;
        return arr;
      })

      let data = {
        id: obj.id,
        updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        updatedBy: userDetails.id,
        ocr: output
      }

      let res = await serverCall({ method: 'PATCH', data, url: `/mapping/phase-plans/${obj.appId}`, eamsAuth0: true });
      if(res.status!==200) return alert('Error updating ocr data. Contact support.') // lklklk
    }    

    setLoading(false);
  }

  return (
    <div className={wrapper}>
      <button type="button" className={classes} onClick={download} disabled={loading}>
        { !loading && text }

        { loading && (
          <Fragment>
            <div className="spinner-border spinner-border-sm me-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            {progress}
          </Fragment>
        )}        
      </button>
      { obj?.ocr?.words?.length > 0 && (
        <p className="mt-1 text-muted small">{obj.ocr.words.length} read {dayjs(obj.ocr.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>
      )} 
    </div>
  )
}

export default View;
