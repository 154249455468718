import { createWorker } from 'tesseract.js';

export const getOcr = async (imgUrl, setProgress, bounds) => {
  return new Promise(async(resolve) => {
    if(!imgUrl) return resolve(null);
    console.clear();

    const worker = await createWorker({
      logger: m => {
        // console.log(m);
        if(m.status!=='recognizing text')
          setProgress('Preparing...')
        else
          setProgress(`Reading ${Math.round(m.progress*100)}%`);
      }
    });
    
    await worker.loadLanguage('eng');
    await worker.initialize('eng');

    // lklklk if needed you will want to refresh the... aws getSigned
    // OR instead do we just move this to a server call?

    const { data: { words } } = await worker.recognize(imgUrl); // also returns text

    let ocr = { words: [] };
    if(words?.length > 0) {
      for(const word of words) {
        if(word.text.length < 2) continue;
        let { x0, x1, y0, y1 } = word.bbox;
        let xCoord = Math.round((x1-x0)/2)+x0;
        let yCoord = Math.round((y1-y0)/2)+y0;
        let obj = { text: word.text, locatorX: xCoord, locatorY: yCoord };
        if(bounds) obj.bbox = { x0, x1, y0, y1 };
        ocr.words.push(obj);
      }
    }

    ocr.updatedAt = Date.now();
    resolve(ocr);
    await worker.terminate();
  })
};

